import { NextResponse, userAgent } from 'next/server'
import type { NextRequest } from 'next/server'

import { Device } from "lib/device"
import type {
  SmartBannerPathValues,
  ImagesFormatPathValues,
  ArticlesPathTemplate,
  RootPathTemplate,
} from "lib/isr"

export const SMART_BANNER_COOKIE = "travel_guide.smart_banner.disabled"

export function middleware(req: NextRequest) {
  const url = req.nextUrl
  const locale = url.locale !== "en" ? "/" + url.locale : ""
  let smartBanner: SmartBannerPathValues = "smart-banner-enabled"

  if (
    req.cookies.get(SMART_BANNER_COOKIE)
    || url.searchParams.get("utm_source") === "inapp"
  ) {
    req.cookies.set(SMART_BANNER_COOKIE, 1)
    smartBanner = "smart-banner-disabled"
  }

  const newPath: RootPathTemplate = `${locale}/${smartBanner}${url.pathname}`
  let rewritedUrl = new URL(newPath, req.url)

  Device.initUaParser(userAgent(req).ua)

  if (url.pathname.startsWith("/articles")) {
    const imagesFormat: ImagesFormatPathValues = Device.isWebPSupported() ? "webp" : "jpg"
    const newPath: ArticlesPathTemplate = `${locale}/${smartBanner}/${imagesFormat}${url.pathname}` 

    rewritedUrl = new URL(newPath, req.url)
  }

  return NextResponse.rewrite(rewritedUrl)
}

export const config = {
  matcher: ["/articles/:slug*", "/city/:slug*", "/"],
}
