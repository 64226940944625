import NextImg from "next/image"
import HTMLParser from "react-html-parser"
import Link from "next/link"
import { ReactSVG } from "react-svg"
import { twMerge } from "tailwind-merge"
import { getArticleUrl } from "lib"
import { useGetArticlesByCity } from "api"
import { BACKGROUND_URLS } from "./guides-list.consts"
import type { City } from "api"

interface GuidesListProps {
  city: City
}

export function GuidesList(props: GuidesListProps) {
  const { city } = props
  const { data: articles = [] } = useGetArticlesByCity(city.id)

  const textClass = `text-${city.color}`

  return (
    <div className="grid space-y-4">
      {articles.map((article, index) => (
        <Link key={article.id} href={getArticleUrl(article.slug)} passHref>
          <a
            key={article.id}
            className="group cursor-pointer rounded-xl bg-black px-3 pt-6 shadow-[0_4px_20px_rgba(0,0,0,0.4)] duration-500 md:mx-4 md:flex md:flex-row-reverse md:items-center md:justify-end md:px-4 md:hover:mx-0 md:hover:px-8 md:hover:pt-10 md:hover:pb-4"
          >
            <div
              className={`mb-2 font-sans text-2xl uppercase tracking-wider group-hover:${textClass} lg:text-5xl`}
            >
              {HTMLParser(article.title)}
            </div>

            <div className="md:mr-6 md:w-[295px] md:flex-none lg:mr-10 lg:w-[380px]">
              <p
                className={twMerge(
                  "font-serif text-sm uppercase lg:text-lg lg:leading-7",
                  textClass,
                )}
              >
                {article.author.nickname}
              </p>

              {article.preview_image && (
                <div className="relative overflow-hidden">
                  <ReactSVG
                    className={twMerge("absolute w-full", textClass)}
                    src={BACKGROUND_URLS[index % BACKGROUND_URLS.length]}
                  />
                  <NextImg
                    src={article.preview_image.url}
                    layout="responsive"
                    width={article.preview_image.width}
                    height={article.preview_image.height}
                    unoptimized
                  />
                </div>
              )}
            </div>
          </a>
        </Link>
      ))}
    </div>
  )
}
