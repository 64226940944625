import { useContext } from "react"
import { twMerge } from "tailwind-merge"
import {
  ContentTemplate,
  LanguageSelect,
  StoreLink,
  StoresButtons,
  Logo,
} from "components"
import { LocaleContext, ThemeContext, Device } from "lib"
import { useGetCommonTranslations } from "api"

import CloudSvg from "public/images/cloud-footer.svg"

export function FooterWithDevil() {
  const data = useContext(LocaleContext)
  const storesAccess = Device.getStoresAccess()

  const { data: translations } = useGetCommonTranslations()

  return (
    <ThemeContext.Provider value="dark">
      <footer className="overflow-hidden">
        <CloudSvg className="h-[190px] text-darkgold-300 md:h-auto md:w-full lg:hidden" />
        <div className="bg-darkgold-300 text-black lg:bg-transparent">
          <ContentTemplate
            className={twMerge(
              "relative",
              storesAccess.androidStores && "pt-8 sm:pt-0",
            )}
          >
            <div className="flex items-center justify-between border-t border-gray-250 py-[19px] md:py-[29px] lg:items-end lg:border-none lg:py-0 ">
              <div className="flex items-center lg:pb-8 lg:text-white">
                <a href={translations?.urls?.onboarding}>
                  <Logo className="h-5 hover:text-gray-250 lg:hover:text-darkgold-300" />
                </a>
                <span className="hidden md:ml-4 md:block md:w-32 md:font-sans-bold md:text-sm">
                  {translations?.locales["shameless_dating"]}
                </span>
              </div>

              <div className="absolute bottom-20 left-0 px-4 pb-7 md:bottom-25 md:px-10 lg:static lg:flex lg:h-[201px] lg:w-[652px] lg:items-end lg:bg-[url('/images/devil.svg')] lg:bg-no-repeat lg:pt-16 lg:pb-[22px] lg:pr-11 lg:pl-[204px]">
                <div className="mb-4 border-black font-serif text-base italic lg:mb-0 lg:flex lg:h-24 lg:items-center lg:border-r lg:text-xl">
                  {translations?.locales["footer.text"]}
                </div>
                <div className="flex flex-col flex-wrap space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 lg:ml-4 lg:w-44 lg:shrink-0 lg:space-y-2 lg:space-x-0">
                  <StoresButtons urls={data.app_urls} Component={StoreLink} />
                </div>
              </div>

              <div className="-mr-3 hidden lg:block lg:pb-8">
                <ThemeContext.Provider value="dark">
                  <LanguageSelect placement="bottom" />
                </ThemeContext.Provider>
              </div>

              <div className="-mr-3 lg:hidden">
                <ThemeContext.Provider value="gold">
                  <LanguageSelect placement="bottom" />
                </ThemeContext.Provider>
              </div>
            </div>
          </ContentTemplate>
        </div>
      </footer>
    </ThemeContext.Provider>
  )
}
