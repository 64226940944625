import HtmlParser from "react-html-parser"
import {
  ContentTemplate,
  RichContentView,
  Sharing,
  SpotifyWidget,
  useSpotify,
} from "components"
import type { ArticleContentProps } from "./article.types"
import type { BackgroundColor } from "api"

export function ArticleContent(props: ArticleContentProps) {
  const {
    article: {
      elements = [],
      city,
      spotify_playlist_url,
      spotify_playlist_title = "",
    },
  } = props
  const isSpotifyAvailable = useSpotify()

  const blocks = elements.map((element) => ({
    key: element.id.toString(),
    component: <RichContentView src={element.Content} />,
    background: element.background,
  }))

  if (isSpotifyAvailable && spotify_playlist_url) {
    blocks.push({
      key: spotify_playlist_url,
      component: (
        <>
          {spotify_playlist_title && (
            <h4 className="mb-6 font-serif text-4xl uppercase tracking-wider text-black lg:mb-8 lg:text-5xl">
              {HtmlParser(spotify_playlist_title)}
            </h4>
          )}
          <div className="h-96 max-w-[620px] md:mb-6 lg:mb-8">
            <SpotifyWidget url={spotify_playlist_url} />
          </div>
        </>
      ),
      background: "white",
    })
  }

  const lastElementIndex = blocks.length - 1

  const getClasses = (background: BackgroundColor) => {
    switch (background) {
      case "gray":
        return "bg-gray-50 text-black"
      case "black":
        return "bg-black text-white"
      case "white":
      default:
        return "bg-white text-black"
    }
  }

  return (
    <article className={city.color}>
      {blocks.map((block, index) => {
        return (
          <div key={block.key} className={getClasses(block.background)}>
            <ContentTemplate className="pt-16 pb-10 lg:pb-8">
              {block.component}
              {index === lastElementIndex && <Sharing />}
            </ContentTemplate>
          </div>
        )
      })}
    </article>
  )
}
