import { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { isServer, safeObject } from "lib"

type HtmlEmbeddedProps = {
  source: string
  uniqId: string
  // htmlEmbeddedStore: { [uniqId: string]: string }
  // setHtmlEmbeddedStore: (uniqId: string, source: string) => void
}

const initialState = {
  init: false,
}

export function HtmlEmbedded(props: HtmlEmbeddedProps) {
  const { source, uniqId } = props
  const [state, setState] = useState(initialState)
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let rootNode = rootRef.current
    if (!rootNode) return

    const slotHtml = document.createRange().createContextualFragment(source)
    rootNode.innerHTML = ""
    rootNode.appendChild(slotHtml)

    setState((state) => ({ ...state, init: true }))

    return function cleanup() {
      if (isServer() && !rootNode) return
      // setHtmlEmbeddedStore(uniqId, rootNode?.innerHTML ?? "")
    }
  }, [source, uniqId])

  return (
    <Root
      className="raw-html-embed"
      ref={rootRef}
      {...safeObject({
        id: state.init ? uniqId : null,
      })}
    />
  )
}

const Root = styled.div`
  transform: translateX(-15px);

  & blockquote {
    padding: 0;
    margin: 0;
    line-height: 1;

    &:before {
      content: unset;
    }

    ${(props) => props.theme.ViewPorts.touch} {
      font-size: inherit;
      line-height: 1;
      padding: 0;
    }

    & p {
      font-size: inherit;
      line-height: 1;
      margin-bottom: 0;
    }
  }
`
