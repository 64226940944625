import { twMerge } from "tailwind-merge"
import { ButtonText } from "ui"
import type { ButtonTextProps } from "ui"

import CloseSvg from "public/images/icons/close.svg"

export function SmartBannerCloseButton(
  props: Omit<ButtonTextProps, "children">,
) {
  const { className, ...rest } = props
  const classes = `h-6 w-6`

  return (
    <ButtonText {...rest} className={twMerge(classes, className)}>
      <CloseSvg className={classes}/>
    </ButtonText>
  )
}
