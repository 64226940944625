import { useRouter } from "next/router"
import HTMLParser from "react-html-parser"
import { Button, ContentTemplate } from "components"
import { useGetArticleBySlug, useGetArticles, useGetPageData } from "api"
import { getArticleUrl } from "lib"

import DiceSvg from "public/images/icons/dice.svg"

export function RandomGuide() {
  const router = useRouter()
  const { data } = useGetPageData("article")
  const { data: articles = [] } = useGetArticles()
  const { data: currentArticle } = useGetArticleBySlug(
    router.query.slug as string,
  )
  const onClick = () => {
    const restArticles = articles.filter(
      (article) => article.slug !== currentArticle?.slug,
    )
    const index = Math.floor(Math.random() * restArticles.length)
    const article = restArticles[index]
    router.push(getArticleUrl(article.slug))
  }

  return (
    <ContentTemplate className="flex flex-col items-center pt-2 md:py-10">
      <p className="mb-6 text-center text-3xl uppercase tracking-widest md:text-6xl lg:max-w-[85%]">
        {HTMLParser(data?.locales["random_guide.text"] || "")}
      </p>
      <Button theme="white" className="px-4 md:w-[360px]" onClick={onClick}>
        <span className="pt-1 pr-1 tracking-wider">
          {HTMLParser(data?.locales["random_guide.button"] || "")}
        </span>
        <DiceSvg className="w-[22px] shrink-0" />
      </Button>
    </ContentTemplate>
  )
}
