import classnames from "classnames"
import styled from "styled-components"

const IMAGES_CLASS = "rich-images"
const IMAGES_ITEM_CLASS = "rich-images-item"

type TBlockBorderUnion = "shadow" | "none"
type TBlockRadiusUnion = "radius" | "none"

interface SliderProps {
  images: string[]
  align: "left" | "right"
  blockBorder: TBlockBorderUnion
  blockRadius: TBlockRadiusUnion
}

export function Images(props: SliderProps) {
  const { images, align, blockBorder, blockRadius } = props

  return (
    <Root
      className={classnames(IMAGES_CLASS, "flex", {
        "justify-start": align === "left",
        "justify-end": align === "right",
      })}
      blockBorder={blockBorder}
      blockRadius={blockRadius}
    >
      {images.map((img, index) => (
        <div className={IMAGES_ITEM_CLASS} key={index}>
          <img src={img} alt="" />
        </div>
      ))}
    </Root>
  )
}

const Root = styled.div<{
  blockBorder: TBlockBorderUnion
  blockRadius: TBlockRadiusUnion
}>`
  & .${IMAGES_ITEM_CLASS} img {
    border-radius: ${(props) =>
      props.blockRadius === "radius" ? "14px" : false};
  }
`
