import { useUserCountry } from "api"

const BLOCKED_COUNTRIES = ["RU"]

export function useSpotify() {
  const { data: country } = useUserCountry()
  if (!country) {
    return false
  }
  return !BLOCKED_COUNTRIES.includes(country)
}
