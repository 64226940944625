import { useGetCommonTranslations } from "api"

import TiktokSvg from "public/images/icons/tiktok.svg"
import TwitterSvg from "public/images/icons/twitter.svg"
import InstagramSvg from "public/images/icons/instagram.svg"
import EmailSvg from "public/images/icons/email.svg"

interface ContactsProps {
  linkClasses?: string
}

export function Contacts(props: ContactsProps) {
  const { linkClasses } = props
  const { data: translations } = useGetCommonTranslations()

  const contacts = [
    {
      Icon: InstagramSvg,
      href: translations?.urls?.instagram,
    },
    {
      Icon: TiktokSvg,
      href: translations?.urls?.tiktok,
    },
    {
      Icon: TwitterSvg,
      href: translations?.urls?.twitter,
    },
    {
      Icon: EmailSvg,
      href: translations?.urls?.email,
    },
  ]

  return (
    <div className="flex space-x-4">
      {contacts.map(({ Icon, href }, index) => (
        <a
          key={index}
          className={linkClasses}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          <Icon className="h-8 w-8" />
        </a>
      ))}
    </div>
  )
}
