import { useContext } from "react"
import { twMerge } from "tailwind-merge"
import { ThemeContext } from "lib"
import { STORES, STORES_BUTTONS } from "./store-link.consts"
import type { StoreLinkProps, StoreButtonProps } from "./store-link.types"

export function StoreLink(props: StoreLinkProps) {
  const { type, className, classes, ...rest } = props
  const { Svg, text } = STORES[type]

  return (
    <a
      className={twMerge("group flex items-center", className)}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <Svg className={twMerge("h-6", classes?.icon)} />
      <div
        className={twMerge(
          "ml-2 box-border h-7 border-current pt-1 text-xl uppercase group-hover:border-b",
          classes?.text,
        )}
      >
        {text}
      </div>
    </a>
  )
}

export function StoreLinkBordered(props: StoreLinkProps) {
  const { type, className, classes, ...rest } = props
  const theme = useContext(ThemeContext)
  const { SolidSvg, text } = STORES[type]

  const themeClasses =
    theme === "white" ? "text-black border-black" : "text-white border-white"

  return (
    <a
      className={twMerge(
        "flex items-center justify-center rounded-md border",
        themeClasses,
        "transition-colors hover:border-darkgold-300 hover:text-darkgold-300",
        className,
      )}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <SolidSvg className={twMerge("h-6", classes?.icon)} />
      <span
        className={twMerge(
          "ml-1.5 whitespace-nowrap pt-1 font-sans-bold text-base",
          classes?.text,
        )}
      >
        {text}
      </span>
    </a>
  )
}

export function StoreButton(props: StoreButtonProps) {
  const { className, type, isAdaptive, ...rest } = props
  const theme = useContext(ThemeContext)
  const { MobileSvg, TabletSvg, DesktopSvg } = STORES_BUTTONS[type]

  const themeClasses =
    theme === "white" ? "text-black" : "text-white"

  return (
    <a
      className={twMerge(
        themeClasses,
        "transition-colors hover:text-darkgold-300",
        className)}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <MobileSvg className={twMerge("h-full w-auto", isAdaptive ? "block md:hidden" : "hidden")} />
      <TabletSvg className={twMerge("h-full w-auto", isAdaptive ? "hidden md:block lg:hidden" : "hidden")} />
      <DesktopSvg className={twMerge("h-full w-auto", isAdaptive ? "hidden lg:block" : "block")} />
    </a>
  )
}
