import { ContentTemplate, Header } from "components"
import { LottieAnimation } from "lib"
import type { PropsWithChildren } from "react"

const ANIMATION_PATH = "/animations/404.json"

type ErrorTemplateProps = PropsWithChildren<{}>

export function ErrorTemplate(props: ErrorTemplateProps) {
  const { children } = props

  const currentYear = new Date().getFullYear()

  return (
    <div className="flex h-full min-h-screen flex-col bg-white">
      <Header theme="white" />
      <ContentTemplate className="flex flex-auto flex-col justify-between pt-6 text-black md:pt-16 lg:flex-row lg:pt-[130px]">
        <div className="lg:w-1/2">{children}</div>
        <div className="mt-10 -mr-4 md:-mr-10 lg:mr-0 lg:flex lg:w-1/2 lg:items-end lg:justify-end">
          <LottieAnimation
            className="h-[280px] md:h-[320px] lg:h-[465px]"
            path={ANIMATION_PATH}
            rendererSettings={{
              preserveAspectRatio: "xMaxYMid meet",
            }}
          />
        </div>
      </ContentTemplate>
      <footer>
        <ContentTemplate>
          <div className="border-t border-gray-100 py-4 text-sm text-black md:text-base">
            © {currentYear} PURE
          </div>
        </ContentTemplate>
      </footer>
    </div>
  )
}
