import { forwardRef } from "react"
import { twMerge } from "tailwind-merge"
import type { ButtonTextProps } from "./button-text.types"

export const ButtonText = forwardRef<HTMLButtonElement, ButtonTextProps>(
  function ButtonPrimaryView(props, ref) {
    const { as: Element = "button", children, className, ...otherProps } = props

    const classes = twMerge(
      "h-15 inline-flex items-center justify-center font-sans-bold text-sm leading-4 tracking-wide uppercase transition-colors duration-100 ease-in cursor-pointer",
      "text-black bg-transparent border-none",
      "hover:text-gray-280 active:text-gray-250 focus:text-gray-250",
      "disabled:bg-text-150",
      className,
    )

    return (
      // @ts-ignore
      <Element ref={ref} className={classes} {...otherProps}>
        {children}
      </Element>
    )
  },
)
