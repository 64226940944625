import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { Http } from "lib"
import type { QueryClient } from "react-query"
import type { Author } from "./authors.types"

async function getAuthors(locale?: string): Promise<Author[]> {
  const data = (await Http.get("/authors", { locale })) as Author[]
  return data || []
}

export const useGetAuthors = () => {
  const { locale } = useRouter()
  return useQuery(["authors", locale], () => getAuthors(locale))
}

export function prefetchAuthors(queryClient: QueryClient, locale?: string) {
  return queryClient.prefetchQuery(["authors", locale], () =>
    getAuthors(locale),
  )
}
