export function SmartBannerLogo(props: any) {
  return (
    <svg
      width="82"
      height="19"
      viewBox="0 0 82 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.5861 12.5742C26.3774 13.841 26.1727 15.1078 25.9621 16.3746C25.8244 17.2072 25.3087 17.7948 24.6316 17.9044C23.4467 18.0956 22.4606 17.1594 22.6614 15.9524C23.0432 13.6777 23.4802 11.413 23.8935 9.14433C24.236 7.26405 24.7241 5.41365 24.8245 3.48955C24.8816 2.39206 25.7437 1.41607 26.9601 1.21091C28.4579 0.957948 29.9577 0.951972 31.2843 1.95187C32.3255 2.73465 32.9396 3.68873 32.9101 5.04915C32.8629 7.31384 31.9201 9.12042 30.2687 10.5884C30.1486 10.694 30.0168 10.7856 29.8337 10.925C29.9617 11.1401 30.066 11.3313 30.186 11.5126C30.8611 12.5284 31.552 13.5343 32.2114 14.5601C32.422 14.8887 32.5991 15.2512 32.7231 15.6197C32.9436 16.279 32.6444 17.0837 32.0952 17.4502C31.554 17.8107 30.7785 17.8028 30.2648 17.3506C29.9518 17.0738 29.7077 16.7073 29.4656 16.3587C28.635 15.1696 27.8162 13.9725 26.9994 12.7754C26.9266 12.6679 26.8931 12.5324 26.84 12.4109C26.7553 12.4667 26.6707 12.5205 26.5861 12.5742ZM27.4403 8.23008C27.5052 8.27589 27.5682 8.31971 27.6332 8.36552C28.8929 7.65843 29.4794 6.49919 29.7018 5.12683C29.0877 4.4018 28.9657 4.354 28.0938 4.48546C27.8773 5.71441 27.6588 6.97125 27.4403 8.23008Z"
        fill="black"
      />
      <path
        d="M1.6465 18.2635C0.199825 17.8751 -0.182017 17.2098 0.073858 15.7199C0.467511 13.4114 0.84739 11.1009 1.17215 8.78243C1.4733 6.62529 1.69178 4.4562 1.94962 2.29109C2.05985 1.36489 2.65426 0.781289 3.57147 0.753404C4.48869 0.725518 5.40786 0.729502 6.32508 0.757387C7.47454 0.791248 8.39963 1.32705 9.11805 2.20544C11.8185 5.51186 9.67113 11.1766 5.41968 11.7562C5.00241 11.814 4.5871 11.8817 4.15998 11.9454C4.10881 12.0251 4.0222 12.1008 4.00842 12.1884C3.77813 13.5528 3.52226 14.9152 3.34315 16.2876C3.18372 17.4827 2.85305 17.9229 1.6465 18.2635ZM4.5438 8.48366C6.44711 8.42589 7.1242 6.56155 7.03169 5.41824C6.94115 4.2849 6.26799 3.86263 5.089 4.12755C4.91382 5.54174 4.73472 6.97186 4.5438 8.48366Z"
        fill="black"
      />
      <path
        d="M42.8817 0.964844C43.7478 1.06842 44.2811 1.40105 44.6374 2.13205C44.6374 2.4109 44.6374 2.76943 44.6374 3.21958C43.4604 4.75328 41.7696 4.70349 39.9707 4.61983C39.8604 5.62769 39.7463 6.66543 39.6203 7.79878C39.9333 7.82865 40.2147 7.84061 40.4923 7.88443C40.8761 7.94418 41.2756 7.97406 41.6338 8.11348C42.3877 8.40429 42.8463 9.2289 42.6554 9.94197C42.4369 10.7586 41.9409 11.3462 40.9823 11.3143C40.3702 11.2944 39.7541 11.3104 39.0692 11.3104C38.9157 12.4457 38.774 13.5073 38.6165 14.6825C39.2581 14.6825 39.8407 14.6666 40.4214 14.6885C40.7422 14.7004 41.0808 14.7243 41.376 14.8379C42.0216 15.0849 42.3286 15.6007 42.3444 16.3019C42.3621 17.003 42.0924 17.5308 41.4508 17.8136C41.187 17.9292 40.8879 18.0447 40.6084 18.0347C39.3684 17.9949 38.1166 17.9949 36.8923 17.8176C35.5086 17.6165 35.0776 16.6982 35.1838 15.6884C35.2704 14.8677 35.3649 14.0471 35.4811 13.2305C35.7133 11.5992 35.9987 9.97384 36.1975 8.33856C36.4239 6.47223 36.5813 4.59593 36.7663 2.72362C36.8608 1.77352 37.3962 1.19789 38.3193 1.11622C38.709 1.08236 39.1243 1.03854 39.4924 1.13614C40.6694 1.45085 41.8149 1.36918 42.8817 0.964844Z"
        fill="black"
      />
      <path
        d="M21.5411 0.886594C22.604 0.970251 23.1531 1.56979 23.3264 2.66529C22.8855 4.94592 22.4603 7.31619 21.9584 9.66853C21.6002 11.3436 21.1947 13.0128 20.4015 14.5525C19.7185 15.877 18.7265 16.9088 17.5298 17.7613C15.967 18.8787 13.8748 18.5421 12.6485 17.1677C11.5443 15.9288 10.9814 14.4947 10.948 12.8455C10.885 9.75617 11.2511 6.70669 11.7884 3.67315C11.8455 3.34848 11.875 3.01983 11.9321 2.69517C12.001 2.30676 12.0384 1.90242 12.1722 1.53593C12.3946 0.922447 12.8552 0.587821 13.5205 0.565911C14.2546 0.54201 14.7191 0.810905 15.0282 1.44829C15.2525 1.91437 15.2958 2.40635 15.227 2.90431C15.1108 3.75482 14.9868 4.60532 14.8313 5.44786C14.3727 7.92967 14.2015 10.4334 14.2645 12.955C14.2861 13.8693 14.6266 14.6142 15.4395 15.1321C16.6205 14.5186 17.3822 13.5426 17.8113 12.3276C18.571 10.1804 19.0474 7.95556 19.4174 5.7068C19.5886 4.66508 19.8091 3.63132 19.9528 2.58761C20.0905 1.60564 20.6062 1.03996 21.5411 0.886594Z"
        fill="black"
      />
      <path
        d="M55.2314 14.5083C54.0898 14.4466 53.0545 14.3908 51.905 14.329C51.8145 14.6079 51.6983 14.9445 51.596 15.2871C51.4543 15.7592 51.3618 16.2512 51.1768 16.7053C51.0272 17.0698 50.8225 17.4383 50.5548 17.7231C50.1041 18.2031 49.3522 18.2609 48.7676 17.9462C48.2047 17.6414 47.813 16.8686 47.9862 16.2053C48.2893 15.0421 48.6101 13.8789 48.9979 12.7436C49.8954 10.1163 50.8008 7.48913 51.783 4.89379C52.1963 3.80227 52.775 2.76852 53.3497 1.74871C54.1921 0.248868 56.0994 0.300655 56.9851 1.87618C57.6051 2.97965 57.8669 4.1847 58.0184 5.40768C58.2959 7.65246 58.4141 9.9072 58.4259 12.1739C58.4337 13.5622 58.6345 14.9465 58.7211 16.3348C58.7605 16.9762 58.5932 17.5737 58.0558 17.982C57.2941 18.5597 56.3552 18.4262 55.7805 17.6554C55.44 17.1993 55.2058 16.6495 55.3357 16.0938C55.4695 15.5381 55.2963 15.066 55.2314 14.5083ZM55.0916 11.174C55.192 9.469 55.009 7.81579 54.7373 6.12872C54.1587 7.7401 53.58 9.35149 52.9679 11.0545C53.7198 11.0963 54.3535 11.1322 55.0916 11.174Z"
        fill="black"
      />
      <path
        d="M74.568 12.1096C74.3908 12.984 74.2353 13.7687 74.07 14.5495C73.885 15.424 73.7019 16.2964 73.4972 17.1668C73.3594 17.7484 72.9678 18.1288 72.4048 18.4177C72.2434 18.4316 72.0308 18.4515 71.8183 18.4655C70.9699 18.5192 70.0783 17.5891 70.2692 16.4796C70.2948 16.3282 70.3283 16.1749 70.3755 16.0295C70.9955 14.1492 71.2514 12.1833 71.586 10.2452C71.7868 9.08399 72.0781 7.9606 72.3595 6.82327C72.6922 5.48078 72.9028 4.10444 73.1272 2.73606C73.3063 1.63259 73.7551 1.07687 74.8297 0.823909C75.3336 0.706391 75.8473 0.614768 76.361 0.557005C77.4259 0.439487 78.3372 0.855778 79.1835 1.45731C80.5771 2.44724 81.2719 3.81562 81.4411 5.51863C81.7206 8.3012 80.1559 9.97433 78.0833 11.3427C77.2487 11.8944 76.3158 12.1813 75.2982 12.1135C75.0895 12.0976 74.877 12.1096 74.568 12.1096ZM75.2135 8.79119C75.3513 8.81708 75.4399 8.8609 75.5186 8.84497C76.4398 8.66969 77.1011 8.08011 77.7054 7.40887C78.5753 6.44084 78.3647 4.81552 77.2743 4.1124C76.987 3.92716 76.6838 3.78176 76.2528 4.00086C76.1091 4.75975 75.9713 5.57241 75.7962 6.3771C75.6229 7.17981 75.4143 7.97056 75.2135 8.79119Z"
        fill="black"
      />
      <path
        d="M64.6921 11.5578C64.5582 12.2469 64.4185 12.9042 64.3043 13.5655C64.1055 14.7188 63.9441 15.878 63.7217 17.0253C63.6351 17.4774 63.4225 17.8917 63.0171 18.1806C61.8873 18.9852 60.5941 18.1626 60.4564 16.9038C60.4072 16.4576 60.5115 15.9856 60.6 15.5354C60.7555 14.7526 60.9839 13.9838 61.1295 13.199C61.3047 12.2609 61.409 11.3088 61.5743 10.3686C61.907 8.49035 62.279 6.61804 62.6018 4.73577C62.7218 4.03863 62.7474 3.32357 62.8163 2.61847C62.8872 1.88946 63.2178 1.33175 63.8772 0.995132C65.5286 0.154584 67.2764 0.411529 68.5755 1.55882C70.0005 2.81566 70.7858 4.44895 70.8921 6.34516C70.9945 8.17166 70.1934 9.60178 68.601 10.4742C67.5559 11.0459 66.4399 11.492 65.2097 11.4821C65.0877 11.4821 64.9676 11.5159 64.6921 11.5578ZM67.5736 7.03831C67.6681 5.50063 67.0933 4.34537 65.9872 3.88128C65.7628 5.29547 65.5404 6.69771 65.316 8.11987C66.4123 7.98642 66.9753 7.71752 67.5736 7.03831Z"
        fill="black"
      />
    </svg>
  )
}
