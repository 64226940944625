import { Device } from "lib"
import { useGetSmartBanner } from "api"
import { SmartBannerIllustration, SmartBannerLogo } from "../atoms"
import { SmartBannerButton, SmartBannerCloseButton } from "../molecules"
import { setSmartBannerCookie } from "../lib"
import type { MouseEvent } from "react"

interface SmartBannerProps {
  onClose: () => void
}
export function SmartBanner(props: SmartBannerProps) {
  const { onClose } = props
  const { data: smartBanner } = useGetSmartBanner()

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation()
    setSmartBannerCookie(smartBanner?.redisplay_interval)
    onClose()
  }

  const handleBannerClick = () => {
    if (smartBanner?.link) {
      window.location.href = smartBanner.link
    }
  }

  const handleButtonClick = (e: MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <div
      onClick={handleBannerClick}
      className="relative flex h-20 cursor-pointer items-center justify-between bg-gold pl-11 pr-3 text-black md:h-auto md:justify-center"
    >
      <SmartBannerCloseButton
        className="absolute left-2 md:left-10"
        onClick={handleClose}
      />
      <SmartBannerIllustration className="my-0.5 mr-3.5 hidden h-12 w-[53px] md:block" />
      <div className="flex w-26 flex-col md:w-auto md:flex-row md:items-center">
        <SmartBannerLogo className="mb-1.5 h-4.5 md:mb-0 md:mr-2 md:h-3.5 md:w-[63px]" />
        <div className="font-sans text-xs uppercase tracking-[1px] md:mt-[3px] md:mr-3.5 md:text-sm">
          {smartBanner?.description}
        </div>
      </div>
      {Device.isAndroid() ? (
        <div className="inline-flex space-x-2">
          <SmartBannerButton
            onClick={handleButtonClick}
            href={smartBanner?.playmarket_link}
          >
            <span className="whitespace-nowrap text-[10px]">
              {smartBanner?.playmarket_button_text}
            </span>
          </SmartBannerButton>
          <SmartBannerButton
            onClick={handleButtonClick}
            href={smartBanner?.appgallery_link}
          >
            <span className="text-[10px]">
              {smartBanner?.appgallery_button_text}
            </span>
          </SmartBannerButton>
        </div>
      ) : (
        <>
          <div className="flex grow justify-center md:hidden">
            <SmartBannerIllustration className="h-20 w-24" />
          </div>
          <SmartBannerButton
            onClick={handleButtonClick}
            href={smartBanner?.link}
          >
            {smartBanner?.button_text}
          </SmartBannerButton>
        </>
      )}
    </div>
  )
}
