import type { Ad } from "./pure-ads.types"

import DolphinsSvg from "public/images/avatar-dolphins.svg"
import ShellfishSvg from "public/images/avatar-shellfish.svg"
import CherrySvg from "public/images/avatar-cherry.svg"
import EmojiSvg from "public/images/avatar-emoji.svg"
import MaskSvg from "public/images/avatar-mask.svg"
import ThroneSvg from "public/images/avatar-throne.svg"
import PunkSvg from "public/images/avatar-punk.svg"

export const ADS: Ad[] = [
  {
    text: "ad.1",
    icon: <MaskSvg />,
    className:
      "bg-gray-100 -rotate-[10deg] w-[190px] md:w-[270px] lg:w-[300px]",
    distance: 1,
  },
  {
    text: "ad.2",
    icon: <ShellfishSvg />,
    className:
      "bg-darkgold-300 rotate-[5deg] w-[170px] h-[160px] md:w-[240px] md:h-[200px] lg:w-[270px] lg:h-[230px]",
    distance: 2,
  },
  {
    text: "ad.3",
    icon: <CherrySvg />,
    className: "bg-purple -rotate-[6deg] w-[210px] md:w-[250px] lg:w-[280px]",
    distance: 1,
  },
  {
    text: "ad.4",
    icon: <ThroneSvg />,
    className: "bg-red rotate-[7.5deg] w-[210px] md:w-[300px] lg:w-[340px]",
    distance: 2,
  },
  {
    text: "ad.5",
    icon: <EmojiSvg />,
    className:
      "bg-gray-100 -rotate-[1.5deg] w-[190px] md:w-[260px] lg:w-[300px]",
    distance: 2,
  },
  {
    text: "ad.6",
    icon: <MaskSvg />,
    className:
      "bg-darkgold-300 rotate-[4.5deg] w-[180px] md:w-[260px] lg:w-[290px]",
    distance: 1,
  },
  {
    text: "ad.7",
    icon: <DolphinsSvg />,
    className: "bg-red -rotate-[7deg] w-[170px] md:w-[210px] lg:w-[250px]",
    distance: 2,
  },
  {
    text: "ad.8",
    icon: <PunkSvg />,
    className: "bg-purple rotate-[2deg] w-[240px] md:w-[300px] lg:w-[350px]",
    distance: 1,
  },
]
