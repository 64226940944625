import { twMerge } from "tailwind-merge"
import type { HTMLAttributes } from "react"

export function ContentTemplate(props: HTMLAttributes<HTMLDivElement>) {
  const { className, children } = props
  return (
    <div
      className={twMerge(
        `w-full px-4 md:mx-auto md:max-w-5xl md:px-10 lg:max-w-screen-xl lg:px-20`,
        className,
      )}
    >
      {children}
    </div>
  )
}
