import { useQuery } from "react-query"
import { useRouter } from "next/router"
import { DEFAULT_LOCALE, Http } from "lib"
import type { QueryClient } from "react-query"
import type { Translations } from "./translations.types"

export async function getTranslations(locale?: string): Promise<Translations> {
  const data = (await Http.get("/translations", { locale })) as Translations
  return (
    data || {
      locales: {},
      urls: {},
    }
  )
}

export const useGetCommonTranslations = () => {
  const { locale } = useRouter()
  return useQuery(["translations", locale], () => getTranslations(locale))
}

export const useGetDefaultTranslations = () => {
  return useQuery(["translations", DEFAULT_LOCALE], () =>
    getTranslations(DEFAULT_LOCALE),
  )
}

export const prefetchCommonTranslations = (
  queryClient: QueryClient,
  locale?: string,
) => {
  return queryClient.prefetchQuery(["translations", locale], () =>
    getTranslations(locale),
  )
}
