import { Swiper, SwiperProps, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import { Device } from "lib"
import {
  TRANSITION_DURATION_DESKTOP,
  TRANSITION_DURATION_MOBILE,
} from "./slider.consts"

export function Slider(props: SwiperProps) {
  const speed = Device.isDesktop()
    ? TRANSITION_DURATION_DESKTOP
    : TRANSITION_DURATION_MOBILE

  return (
    <Swiper
      speed={speed}
      slidesPerView="auto"
      grabCursor
      modules={[Autoplay]}
      {...props}
    />
  )
}

export { SwiperSlide as Slide }
