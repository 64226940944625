import Image from "next/image"
import classNames from "classnames"
import parse from "react-html-parser"
// @ts-ignore
import sha1 from "js-sha1"
import styled from "styled-components"
import { commonPropsToCss, applyCommonProps, unsetCommonProps } from "lib"

import { RichContent } from "./rich-content"
import { HtmlEmbedded } from "./html-embedded"
import { ArticleSlider } from "./slider"
import { Images } from "./images"
import {
  CAROUSEL_CLASS,
  IMAGES_CLASS,
  AUTHOR_IMAGE_CLASS,
  BANNER_CLASS,
  BANNER_IMAGE_CLASS,
} from "./consts"

import type { CSSProperties } from "react"

const htmlParser = require("htmlparser2")

interface BlogContentProps {
  className?: string
  style?: CSSProperties
  src?: string
  title?: string
}

export function RichContentView(props: BlogContentProps) {
  const { className, style, src, title, ...otherProps } = props

  if (!src) return null

  const transformHtml = () => {
    let counter = 1
    return parse(src, {
      decodeEntities: true,
      // @ts-ignore
      transform: (node, index: number) => {
        const { name = "", attribs = {}, children = [] } = node

        if (name === "figure" && attribs?.class?.startsWith("image")) {
          const {
            attribs: { src = "", alt = `${title}.${counter++}` },
          } = children[0]
          return (
            <div className={attribs.class}>
              <img src={src} alt={alt} />
            </div>
          )
        }

        if (name === "div" && attribs?.class === "raw-html-embed") {
          const source = htmlParser.DomUtils.getOuterHTML(children, {
            xmlMode: true,
          })
          const uniqId = sha1.create()
          uniqId.update(source)
          uniqId.hex()

          return <HtmlEmbedded key={index} uniqId={uniqId} source={source} />
        }

        if (attribs?.class === CAROUSEL_CLASS) {
          const images = children.map(
            (child: any) => child?.attribs?.["data-source"] ?? "",
          )
          return <ArticleSlider images={images} key={index} />
        }

        if (attribs?.class === BANNER_CLASS) {
          const image = children.find(
            // @ts-ignore
            (child) => child.attribs.class === BANNER_IMAGE_CLASS,
          )
          const { "data-source": imgSrc } = image.attribs
          const href =
            image.attribs["data-href"] !== "undefined"
              ? image.attribs["data-href"]
              : undefined

          const imgDOMNode = `<div class="${BANNER_IMAGE_CLASS}">
                <img src="${imgSrc}" />
              </div>`

          const content = children.find(
            // @ts-ignore
            (child) => child.attribs.class === "editor-banner-content",
          )

          return (
            <a
              key={index}
              className={BANNER_CLASS}
              href={href}
              dangerouslySetInnerHTML={{
                __html:
                  imgDOMNode +
                  htmlParser.DomUtils.getOuterHTML(content, {
                    xmlMode: true,
                  }),
              }}
              rel="noreferrer"
            />
          )
        }

        if (name === "a") {
          return (
            <a
              key={index}
              href={attribs?.href}
              target="_blank"
              dangerouslySetInnerHTML={{
                __html: htmlParser.DomUtils.getOuterHTML(children, {
                  xmlMode: true,
                }),
              }}
              rel="noreferrer"
            />
          )
        }

        if (attribs?.class === AUTHOR_IMAGE_CLASS) {
          const source = attribs["data-source"]
          return (
            <div key={index} className="relative mr-4 h-[68px] w-[68px] ">
              <Image
                src={source}
                layout="fill"
                alt="Author"
                className="rounded-full"
                unoptimized={true}
              />
            </div>
          )
        }

        if (attribs?.class === IMAGES_CLASS) {
          const images = children.map(
            // @ts-ignore
            (child) => child?.attribs?.["data-source"] ?? "",
          )
          return (
            <Images
              key={index}
              images={images}
              align={attribs["data-block-align"] ?? "left"}
              blockBorder={attribs["data-block-border"] ?? "none"}
              blockRadius={attribs["data-block-radius"] ?? "none"}
            />
          )
        }
      },
    })
  }

  return (
    <Root
      className={classNames("ck-content", className)}
      style={style}
      {...otherProps}
      {...applyCommonProps(otherProps)}
    >
      {transformHtml()}
    </Root>
  )
}

const Root = styled((props) => {
  const resolveProps = { ...props }
  unsetCommonProps(resolveProps)
  return <RichContent {...resolveProps} />
})`
  max-width: 750px;

  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
  ${(props) => commonPropsToCss(props)}
`
