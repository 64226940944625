import { twMerge } from "tailwind-merge"

import NextImg from "next/image"
import Link from "next/link"
import HTMLParser from "react-html-parser"
import type { GuideCardProps } from "./guide-card.types"

export function GuideCard(props: GuideCardProps) {
  const {
    author,
    city,
    description,
    url,
    backgroundImage,
    className,
    rotateDegree,
  } = props

  return (
    <Link href={url}>
      <a className="group block w-full cursor-pointer px-1">
        <div
          className={twMerge(
            "relative h-[212px] bg-bottom bg-no-repeat outline-none duration-300 will-change-transform md:h-[280px]",
            city && `bg-${city.color}`,
            className,
          )}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            transform: `rotate(${rotateDegree})`,
          }}
        >
          <div className="px-4 pt-4 text-xl uppercase tracking-widest text-gray-400">
            {author.nickname}
          </div>
          {author.photo && (
            <div className="absolute right-0 bottom-0 h-[172px] w-full md:h-[228px]">
              <NextImg
                draggable={false}
                src={author.photo?.url}
                alt={author.nickname}
                layout="fill"
                objectFit="contain"
                objectPosition="right bottom"
                unoptimized
              />
            </div>
          )}
        </div>
        {description && (
          <div className="mt-6 font-serif text-xl uppercase tracking-widest group-hover:italic md:text-2xl">
            {HTMLParser(description)}
          </div>
        )}
      </a>
    </Link>
  )
}
