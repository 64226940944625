import { twMerge } from "tailwind-merge"
import { Slide, Slider } from "ui"
import { GuideCard } from "components"
import { useWindowWidth } from "lib"
import {
  BACKGROUND_URLS,
  SLIDER_GAP,
  CARD_BREAKPOINT,
  DESKTOP_CARD_WIDTH,
  MOBILE_CARD_WIDTH,
} from "./guides-carousel.consts"
import type { GuidesCarouselProps } from "./guides-carousel.types"
import type { Card } from "components"
import styles from "./guides-carousel.module.css"

export function GuidesCarousel(props: GuidesCarouselProps) {
  const { cards } = props
  const width = useWindowWidth()

  const cardWidth =
    width > CARD_BREAKPOINT ? DESKTOP_CARD_WIDTH : MOBILE_CARD_WIDTH
  const isLooped = cards.length > 1 && cards.length * cardWidth > width

  return (
    <Slider
      className={twMerge(styles.swiper, "!pt-3", !isLooped && styles.center)}
      spaceBetween={SLIDER_GAP}
      loop={isLooped}
      centeredSlides={isLooped}
      enabled={isLooped}
      key={isLooped.toString()}
    >
      {cards.map((card: Card, index: number) => (
        <Slide
          style={{
            width: `${cardWidth}px`,
          }}
          key={card.url}
        >
          <GuideCard
            rotateDegree={`${index % 2 === 0 ? "2deg" : "-2deg"}`}
            backgroundImage={BACKGROUND_URLS[index % BACKGROUND_URLS.length]}
            {...card}
          />
        </Slide>
      ))}
    </Slider>
  )
}
