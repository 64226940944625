import { NextSeo } from "next-seo"
import type { MetaData } from "api"

interface HeadMetaProps {
  title: string
  meta?: MetaData
  url: string
}

export const HeadMeta = (props: HeadMetaProps) => {
  const { title, meta, url } = props
  return (
    <NextSeo
      title={title}
      description={meta?.description}
      openGraph={{
        type: "website",
        url,
        title: meta?.title || title,
        description: meta?.description,
        images: meta?.image && [
          {
            url: meta.image.url,
          },
        ],
        site_name: "PURE.APP",
      }}
      twitter={{
        cardType: "summary_large_image",
      }}
      additionalLinkTags={[
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicon-16x16.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "96x96",
          href: "/favicon-96x96.png",
        },
      ]}
    />
  )
}
