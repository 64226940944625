import { CityLine } from "components"
import type { City } from "api"

type CityBoardProps = {
  cities: City[]
}

export function CityBoard(props: CityBoardProps) {
  const { cities = [] } = props

  return (
    <section>
      {cities.map((city) => (
        <CityLine key={city.id} city={city} />
      ))}
    </section>
  )
}
