import { Device } from "lib"
import type { StoreLinkProps } from "components"
import type { AppUrls } from "api"

interface StoresButtonsProps {
  urls?: AppUrls
  Component: (props: StoreLinkProps) => JSX.Element
}

export function StoresButtons(props: StoresButtonsProps) {
  const { urls, Component } = props
  const storesAccess = Device.getStoresAccess()

  return (
    <>
      {storesAccess.appStore && (
        <Component href={urls?.app_store} type="apple" />
      )}
      {storesAccess.androidStores && (
        <>
          <Component href={urls?.google_play} type="google" />
          <Component href={urls?.app_gallery} type="huawei" />
        </>
      )}
    </>
  )
}
