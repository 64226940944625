import { useContext } from "react"
import Link from "next/link"
import { twMerge } from "tailwind-merge"
import {
  ContentTemplate,
  Menu,
  LanguageSelect,
  Logo,
  StoresButtons,
  StoreButton,
} from "components"
import { Device, LocaleContext, ThemeContext } from "lib"
import { useGetCommonTranslations } from "api"
import type { Theme } from "lib"
import type { City } from "api"

interface HeaderProps {
  theme: Theme
  variant?: "menu" | "default"
  currentCity?: City
}

export function Header(props: HeaderProps) {
  const { theme, currentCity, variant = "default" } = props
  const data = useContext(LocaleContext)
  const { data: translations } = useGetCommonTranslations()
  const isWhite = theme === "white"
  const showMenu = variant === "menu"

  const themeClasses = isWhite
    ? "bg-white text-black"
    : "bg-gray-400 text-white"

  const logoDefaultClasses =
    "text-[22px] leading-none tracking-wider ml-2 uppercase font-serif text-darkgold-300 md:text-5xl md:ml-4 duration-300 hover:text-darkgold-400"
  const logoClasses = Device.isWindows()
    ? "-mt-1.5 md:-mt-3"
    : "mt-[5px] md:mt-2"

  return (
    <ThemeContext.Provider value={theme}>
      <header className={themeClasses}>
        <ContentTemplate
          className={twMerge(
            "flex justify-between py-[7px] md:py-[11px]",
            !showMenu && "pr-1 md:pr-7 lg:pr-[68px]",
          )}
        >
          <Link href="/">
            <a className="flex items-center">
              <Logo />
              <span className={twMerge(logoDefaultClasses, logoClasses)}>
                {translations?.locales["guides"]}
              </span>
            </a>
          </Link>
          <div
            className={twMerge(
              "flex items-center",
              showMenu ? "space-x-3 md:space-x-4 lg:space-x-6" : "space-x-1",
            )}
          >
            <div className="flex space-x-2">
              <StoresButtons
                urls={data?.app_urls}
                Component={(props) => (
                  <StoreButton
                    className="h-6 md:h-10"
                    isAdaptive
                    {...props}
                  />
                )}
              />
            </div>
            {variant === "menu" ? (
              <Menu currentCity={currentCity} />
            ) : (
              <LanguageSelect placement="top" />
            )}
          </div>
        </ContentTemplate>
      </header>
    </ThemeContext.Provider>
  )
}
