import { useContext, useEffect, useRef, useState, useMemo } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import AnimateHeight from "react-animate-height"
import cn from "classnames"
import {
  DEFAULT_LOCALE,
  getHumanReadableLanguageName,
  ThemeContext,
  isServer,
} from "lib"
import ArrowTriangle from "public/images/icons/arrow-triangle-down.svg"
import type { LanguageSelectProps } from "./language-select.types"
import styles from "./language-select.module.scss"

const OMITED_PATH_PARTS = ["smartBanner", "imagesFormat"]

export const LanguageSelect = ({ placement }: LanguageSelectProps) => {
  const [opened, setOpened] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const optionRef = useRef(null)
  const router = useRouter()

  const {
    pathname,
    query,
    locale = DEFAULT_LOCALE,
    locales = [],
  } = router
  const isBottom = placement === "bottom"
  const isWhite = theme === "white"
  const isGold = theme === "gold"

  const resolvePathname = useMemo(() => (
    pathname
      .split("/")
      .filter((pa) => !OMITED_PATH_PARTS.some((p) => pa.includes(p)))
      .join("/")
    || "/"
  ), [pathname])

  const resovedQuery = useMemo(() => {
    const resolved = { ...query }

    OMITED_PATH_PARTS.forEach(key => {
      delete resolved[key]
    })

    return resolved
  }, [query])

  useEffect(() => {
    const closeSelect = (e: any) => {
      if (optionRef && optionRef.current != e.target) {
        setOpened(false)
      }
    }
    if (opened) {
      window.addEventListener("click", closeSelect)
    } else {
      window.removeEventListener("click", closeSelect)
    }
    return () => {
      window.removeEventListener("click", closeSelect)
    }
  }, [opened])

  const handleOptionClick = () => {
    setOpened(!opened)
  }

  const renderLinks = () =>
    locales.map(
      (value) =>
        value !== locale && (
          <Link key={value} href={{ pathname: resolvePathname, query: resovedQuery }} locale={value} passHref>
            <a
              className={cn(styles.option, {
                [styles.opened]: opened,
                [styles.white]: isWhite,
                [styles.gold]: isGold,
                [styles.bottom]: isBottom,
              })}
            >
              {getHumanReadableLanguageName(value)}
            </a>
          </Link>
        ),
    )

  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.option, {
          [styles.opened]: opened,
          [styles.white]: isWhite,
          [styles.gold]: isGold,
          [styles.bottom]: isBottom,
        })}
        ref={optionRef}
        onClick={handleOptionClick}
      >
        {getHumanReadableLanguageName(locale)}
        <ArrowTriangle className={styles.arrowIcon} />
      </div>
      {isServer() ? (
        renderLinks()
      ) : (
        <AnimateHeight
          className={cn(styles.items, {
            [styles.bottom]: isBottom,
          })}
          height={opened ? "auto" : 0}
          duration={150}
        >
          {renderLinks()}
        </AnimateHeight>
      )}
    </div>
  )
}
