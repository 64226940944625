import { useState } from "react"
import HtmlParser from "react-html-parser"
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share"
import { useGetCommonTranslations } from "api"
import { isServer } from "lib"

import TelegramSvg from "public/images/icons/telegram.svg"
import WhatsUpSvg from "public/images/icons/whatsup.svg"
import FacebookSvg from "public/images/icons/facebook.svg"
import TwitterSvg from "public/images/icons/twitter.svg"
import VkSvg from "public/images/icons/vk.svg"
import CopySvg from "public/images/icons/copy.svg"

const ALERT_TIMEOUT = 3000

export function Sharing() {
  const { data } = useGetCommonTranslations()
  const [showAlert, setShowAlert] = useState<boolean>(false)

  const shareLink = isServer() ? "" : window?.location.href

  const copyLink = () => {
    navigator.clipboard.writeText(shareLink)
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, ALERT_TIMEOUT)
  }

  const iconStyles = "h-8 w-8 cursor-pointer hover:text-darkgold-300"

  return (
    <div className="content-center py-6 text-black md:flex">
      <p className="mb-6 text-lg uppercase tracking-widest md:mr-6 md:mb-0 md:mt-2">
        {data?.locales["share"]}
      </p>
      <div className="flex w-full justify-between md:max-w-[312px]">
        <TwitterShareButton url={shareLink}>
          <TwitterSvg className={iconStyles} />
        </TwitterShareButton>

        <WhatsappShareButton url={shareLink}>
          <WhatsUpSvg className={iconStyles} />
        </WhatsappShareButton>

        <TelegramShareButton url={shareLink}>
          <TelegramSvg className={iconStyles} />
        </TelegramShareButton>

        <FacebookShareButton url={shareLink}>
          <FacebookSvg className={iconStyles} />
        </FacebookShareButton>

        <VKShareButton url={shareLink}>
          <VkSvg className={iconStyles} />
        </VKShareButton>

        <button className="relative" onClick={copyLink}>
          <CopySvg className={iconStyles} />

          {showAlert && (
            <div
              className="absolute -top-10 -right-3 my-[3px] rounded-[20px] bg-gold py-1 px-3 text-sm text-black duration-300 md:top-0 md:left-10 md:right-auto"
              onClick={() => setShowAlert(false)}
            >
              {HtmlParser(data?.locales["copied"] || "")}
            </div>
          )}
        </button>
      </div>
    </div>
  )
}
