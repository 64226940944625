import NextLink from "next/link"
import { twMerge } from "tailwind-merge"
import type { PropsWithChildren } from "react"
import type { LinkProps } from "next/link"
import type { Theme } from "lib"

type ButtonLinkProps = PropsWithChildren<
  LinkProps & {
    className?: string
    theme?: Theme
  }
>

export function ButtonLink(props: ButtonLinkProps) {
  const { theme, children, href, className, ...rest } = props
  const themeClasses =
    theme === "white" ? "bg-gray-400 text-white" : "bg-white text-black"

  return (
    <NextLink href={href || ""} {...rest}>
      <a
        className={twMerge(
          themeClasses,
          "flex w-full justify-center py-4 font-sans-bold text-xl uppercase leading-7 tracking-wide transition-colors",
          "hover:bg-darkgold-300 hover:text-black md:min-w-[302px]",
          className,
        )}
      >
        {children}
      </a>
    </NextLink>
  )
}
