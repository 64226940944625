import axios from "axios"
import { useQuery } from "react-query"

export async function getUserCountry(): Promise<string> {
  const { data } = await axios.get(`/api/geo`)
  return data.country_code || ""
}

export const useUserCountry = () => {
  return useQuery("country", getUserCountry)
}
