import Image from "next/image"
import { Slider, SliderSlide } from "components"

interface SliderProps {
  images: string[]
}

export function ArticleSlider(props: SliderProps) {
  const { images } = props

  return (
    <section className="relative mb-9 select-none">
      <Slider
        className="-mr-4 lg:mr-0"
        options={{
          spaceBetween: 16,
          roundLengths: true,
          slidesPerView: "auto",
          loop: true,
          updateOnWindowResize: true,
          navigation: false,
          breakpoints: {
            320: {
              navigation: false,
              enabled: images.length > 1,
            },
            768: {
              navigation: true,
              slidesPerView: 2,
              enabled: images.length > 2,
            },
            1024: {
              navigation: true,
              slidesPerView: 3,
              enabled: images.length > 3,
            },
          },
        }}
        classes={{
          container: "-m-4 p-4 bg-no-repeat bg-center md:ml-2 md:mr-0",
        }}
      >
        {images.map((src, index) => (
          <SliderSlide
            key={index}
            className="h-[460px] w-full max-w-[90%] md:max-w-none"
          >
            <Image
              src={src}
              layout="fill"
              objectFit="contain"
              unoptimized={true}
            />
          </SliderSlide>
        ))}
      </Slider>
    </section>
  )
}
