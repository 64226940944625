import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { Http } from "lib"
import type { QueryClient } from "react-query"
import type { HttpParams } from "lib"
import type { Article } from "./articles.types"

export async function getArticles(params: HttpParams): Promise<Article[]> {
  const data = (await Http.get("/articles", params)) as Article[]
  return data || []
}

export async function getArticlesBySlug(
  slug?: string,
  locale?: string,
): Promise<Article[]> {
  const data = await getArticles({ locale, slug })
  return data || []
}

export const useGetArticles = () => {
  const { locale } = useRouter()
  return useQuery(["articles", "all", locale], () => getArticles({ locale }))
}

export const useGetAllArticlesBySlug = (slug?: string) => {
  return useQuery(["articles", slug], () => getArticlesBySlug(slug, "all"))
}

export const fetchAllArticlesBySlug = (
  queryClient: QueryClient,
  slug?: string,
) => {
  return queryClient.fetchQuery(["articles", slug], () =>
    getArticlesBySlug(slug, "all"),
  )
}

export const useGetArticleBySlug = (slug?: string) => {
  const { locale } = useRouter()
  return useQuery(["article", slug, locale], async () => {
    const articles = await getArticlesBySlug(slug, locale)
    return articles[0]
  })
}

export const fetchArticleBySlug = (
  queryClient: QueryClient,
  slug?: string,
  locale?: string,
) => {
  return queryClient.fetchQuery(["article", slug, locale], async function () {
    const articles = await getArticlesBySlug(slug, locale)
    return articles[0] || null
  })
}

export const useGetArticlesByCity = (city?: number) => {
  const { locale } = useRouter()
  return useQuery(["articles", city, locale], () =>
    getArticles({ city, locale, _sort: "published_at:DESC" }),
  )
}

export function prefetchArticlesByCity(
  queryClient: QueryClient,
  city?: number,
  locale?: string,
) {
  return queryClient.prefetchQuery(["articles", city, locale], () =>
    getArticles({ city, locale, _sort: "published_at:DESC" }),
  )
}
