import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { Http } from "lib"
import { QueryClient } from "react-query"
import type { SmartBannerData } from "./smart-banner.types"

export async function getSmartBanner(
  locale?: string,
): Promise<SmartBannerData> {
  const data = (await Http.get(`/smart-banner`, { locale })) as SmartBannerData
  return data
}

export const useGetSmartBanner = () => {
  const { locale } = useRouter()
  return useQuery(["smart-banner", locale], () => getSmartBanner(locale))
}

export function prefetchSmartBanner(queryClient: QueryClient, locale?: string) {
  return queryClient.prefetchQuery(["smart-banner", locale], () =>
    getSmartBanner(locale),
  )
}
