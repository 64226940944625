import { twMerge } from "tailwind-merge"
import LogoSVG from "public/images/icons/logo.svg"

interface LogoProps {
  className?: string
}

export function Logo(props: LogoProps) {
  const { className } = props

  const defaultClasses = "h-4 md:h-[29px] duration-300 hover:text-darkgold-300"

  return <LogoSVG className={twMerge(defaultClasses, className)} />
}
