import NextImg from "next/image"
import HTMLParser from "react-html-parser"
import { ContentTemplate, SpotifyWidget } from "components"
import { ReactSVG } from "react-svg"
import type { City } from "api"
import { twMerge } from "tailwind-merge"

const BACKGROUND_URL = "/images/playlist_background.svg"

interface CityPlaylistProps {
  city: City
}

export function CityPlaylist(props: CityPlaylistProps) {
  const {
    city: { spotify_playlist: playlist, color },
  } = props

  const image = playlist?.image ? (
    <NextImg
      src={playlist.image.url}
      layout="responsive"
      objectFit="contain"
      width={playlist.image.width}
      height={playlist.image.height}
      className="w-3/5"
      unoptimized
    />
  ) : null

  const textClass = `text-${color}`

  return (
    <div className="bg-gray-50">
      <ContentTemplate className="flex flex-col items-center pt-12 pb-16 md:pt-18 md:pb-24 lg:flex-row lg:pt-20 lg:pb-[88px] xl:px-48">
        <div className="relative mb-4 flex w-full max-w-[343px] items-center justify-center md:mb-6 lg:mr-16 lg:mb-0 lg:max-w-[418px]">
          <ReactSVG
            className={twMerge("w-full", textClass)}
            src={BACKGROUND_URL}
          />
          <div className="absolute w-[240px]">{image}</div>
        </div>
        <div>
          <p className="mb-4 text-center font-sans text-3xl uppercase text-black md:mb-6 md:text-6xl lg:text-left">
            {playlist?.title && HTMLParser(playlist.title)}
          </p>
          <div className="mx-auto h-20 w-full max-w-[530px] lg:m-0">
            <SpotifyWidget url={playlist?.url || ""} />
          </div>
        </div>
      </ContentTemplate>
    </div>
  )
}
