import { twMerge } from "tailwind-merge"
import type { ButtonHTMLAttributes } from "react"
import type { Theme } from "lib"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme: Theme
}

export function Button(props: ButtonProps) {
  const { className, children, theme, ...rest } = props
  const defaultClasses =
    "inline-flex w-full justify-center items-center py-[21px] font-sans-bold text-sm uppercase tracking-wide transition-all hover:bg-darkgold-300 hover:text-black"
  const themeClasses =
    theme === "white" ? "bg-gray-400 text-white" : "bg-white text-black"
  return (
    <button
      className={twMerge(defaultClasses, themeClasses, className)}
      {...rest}
    >
      {children}
    </button>
  )
}
