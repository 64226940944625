import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { Http } from "lib"
import type { QueryClient } from "react-query"
import type { PageData, PageType } from "./page.types"

export async function getPageData(
  pageType: PageType,
  locale?: string,
): Promise<PageData> {
  const data = (await Http.get(`/${pageType}-page`, { locale })) as PageData
  return (
    data || {
      locales: {},
      app_urls: {},
    }
  )
}

export const useGetPageData = (pageType: PageType) => {
  const { locale } = useRouter()
  return useQuery(["page", pageType, locale], () =>
    getPageData(pageType, locale),
  )
}

export const prefetchPageData = (
  queryClient: QueryClient,
  pageType: PageType,
  locale?: string,
) => {
  return queryClient.prefetchQuery(["page", pageType, locale], () =>
    getPageData(pageType, locale),
  )
}
