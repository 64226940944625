import { useState } from "react"
import Link from "next/link"
import ReactTooltip from "react-tooltip"
import { ReactSVG } from "react-svg"
import cn from "classnames"
import { useGetCommonTranslations } from "api"
import { getCityUrl, getPlural } from "lib"
import type { Translations } from "api"
import type { CityLineProps } from "./city-line.types"
import styles from "./city-line.module.scss"
import IconArrowRight from "public/images/icons/arrow-right.svg"
import SvgTagNew from "public/images/tag-new.svg"
import SvgTagSpecial from "public/images/tag-special.svg"
import { useGetDefaultTranslations } from "api/translations/translations"

// ToDo refactor
export function CityLine(props: CityLineProps) {
  const { city } = props
  const [isHover, setIsHover] = useState<boolean>(false)
  const { data: firstLanguage } = useGetCommonTranslations()
  const { data: secondLanguage } = useGetDefaultTranslations()

  const url = getCityUrl(city.slug)
  const articlesCount = city.articles.length
  const hasArticles = articlesCount > 0
  const tooltipId = city.id.toString()

  const renderRow = (data: Translations, cityName?: string) => {
    const renderTag = () => {
      let tags = []
      if (city.is_new) {
        tags.push(
          <div
            key="new"
            className={cn(styles.specialLabel, styles[city.color])}
          >
            <SvgTagNew />
            <div>{data?.locales["city_board.new"]}</div>
          </div>,
        )
      }
      if (city.is_special) {
        tags.push(
          <div
            key="soon"
            className={cn(styles.specialLabel, styles[city.color])}
          >
            <SvgTagSpecial />
            <div>{data?.locales["city_board.special"]}</div>
          </div>,
        )
      }
      return tags
    }

    return (
      <Link href={hasArticles ? url : ""} passHref scroll={hasArticles}>
        <a
          className={cn(styles.row, styles.animation, {
            [styles[city.color]]: isHover,
          })}
          onMouseEnter={() => hasArticles && setIsHover(true)}
          onMouseLeave={() => hasArticles && setIsHover(false)}
          data-for={tooltipId}
          data-tip
        >
          <div className={styles.cityWrap}>
            <div className={styles.city}>{cityName || city.name}</div>
            <div className={styles.count}>
              {articlesCount
                ? `${articlesCount} ${
                    data?.locales[`sexcursion.${getPlural(articlesCount)}`]
                  }`
                : data?.locales["city_board.soon"]}
              {renderTag()}
            </div>
          </div>
          <IconArrowRight
            className={cn(styles.arrowRightRotate, {
              [styles.arrowRight]: isHover,
              [styles[city.color]]: isHover,
            })}
          />
        </a>
      </Link>
    )
  }
  return (
    <>
      <div
        className={cn(styles.grid, {
          [styles.gray]: !hasArticles,
        })}
      >
        {firstLanguage && renderRow(firstLanguage)}
        {secondLanguage && renderRow(secondLanguage, city.english_name)}
      </div>
      {city.image && hasArticles && (
        <div className={styles.tooltip}>
          {/* @ts-ignore */}
          <ReactTooltip
            className={cn(styles.cityPhoto, styles[city.color])}
            arrowColor="transparent"
            offset={{ top: 0, right: 60 }}
            id={tooltipId}
          >
            <ReactSVG src={city.image.url} />
          </ReactTooltip>
        </div>
      )}
    </>
  )
}
