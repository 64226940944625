import { Fragment, createElement } from "react"
import styled, { createGlobalStyle } from "styled-components"

import {
  AUTHOR_CLASS,
  AUTHOR_NAME_CLASS,
  BANNER_CLASS,
  BANNER_CONTENT_CLASS,
  BANNER_IMAGE_CLASS,
  BANNER_IMAGE_REPLACE_ICON_CLASS,
  BANNER_SECONDARY_TEXT_CLASS,
  BANNER_TEXT_CLASS,
  BANNER_TITLE_CLASS,
  CAROUSEL_CLASS,
  CAROUSEL_ITEM_CLASS,
  COLORS,
  GRID_CLASS,
  GRID_ITEM_SECOND_CLASS,
  IMAGES_CLASS,
  IMAGES_ITEM_CLASS,
  VIEWPORTS,
} from "./consts"

import type { HTMLAttributes } from "react"

const Root = styled.div`
  * {
    box-sizing: border-box;
    font-family: "Figgins Sans V3";
  }

  font-size: 20px;
  font-family: "Figgins Sans V3";
  line-height: 100%;
  letter-spacing: 0.5px;

  p,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  a,
  img,
  input,
  textarea,
  span,
  i,
  b,
  strong,
  em {
    margin: 0;
    padding: 0;
    font-family: "Figgins Sans V3";
  }

  i,
  em {
    font-family: "William Regular A";
    font-style: italic;
  }

  b,
  strong {
    font-family: "Figgins Sans V3 Bold";
  }

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      color: #aa8c64;
    }
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    margin-bottom: 32px;
    font-weight: 400;
    color: inherit;
    word-break: break-word;
    text-transform: uppercase;

    ${VIEWPORTS.touch} {
      margin-bottom: 24px;
    }

    & b,
    strong {
      font-family: "William Regular A";
      font-weight: normal;
    }
  }

  h2 {
    font-size: 72px;
    line-height: 80px;
    letter-spacing: 3px;

    ${VIEWPORTS.touch} {
      font-size: 28px;
      line-height: 35px;
      letter-spacing: 2px;
    }
  }

  h3 {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 1px;

    ${VIEWPORTS.touch} {
      font-size: 28px;
      line-height: 35px;
      letter-spacing: 2px;
    }
  }

  h4 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 2px;

    ${VIEWPORTS.touch} {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h5 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.5px;
    font-family: "William Regular A";
    margin: 0;
    padding: 24px 0;

    ${VIEWPORTS.touch} {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 2px;
    }
  }

  p {
    margin-bottom: 32px;
    word-break: break-word;

    ${VIEWPORTS.touch} {
      margin-bottom: 24px;
    }
  }

  blockquote {
    position: relative;
    padding: 24px 0 0 108px;
    margin: 0 0 56px;
    font-size: 34px;
    line-height: 45px;
    font-style: normal;
    font-family: "William Regular A";
    border-left: none;

    * {
      font-family: "William Regular A";
    }

    &:before {
      top: 0;
      position: absolute;
      content: " ";
      width: 92px;
      height: 5px;
      background: ${COLORS.black};
    }

    & p {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 24px;

      ${VIEWPORTS.mobile} {
        font-size: 28px;
        line-height: 36px;
      }
    }

    ${VIEWPORTS.touch} {
      font-size: 26px;
      line-height: 38px;
      padding: 24px 0 0 80px;
    }

    ${VIEWPORTS.mobile} {
      margin-bottom: 48px;
      padding: 24px 0 0 48px;
    }

    @media screen and (max-width: 374px) {
      padding-top: 24px;
      padding-left: 10px;
    }
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    margin-bottom: 24px;
    padding-left: 40px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  p,
  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  blockquote,
  ol,
  li {
    line-height: 32px;
    word-break: break-word;
  }

  // Embed
  .raw-html-embed {
    & blockquote {
      padding: 0;
      margin: 0;
      line-height: 1;

      &:before {
        content: unset;
      }

      ${VIEWPORTS.touch} {
        font-size: inherit;
        line-height: 1;
        padding: 0;
      }

      & p {
        font-size: inherit;
        line-height: 1;
        margin-bottom: 0;
      }
    }

    text-align: center;

    // For Instagram
    & > iframe.instagram-media {
      display: inline-block;
    }

    // For TikTok
    & > blockquote.tiktok-embed {
      display: inline-block;
    }

    // For Twitter
    & > div.twitter-tweet {
      display: inline-flex;
    }
  }

  // Rich Carousel
  .${CAROUSEL_CLASS} {
    display: flex;
    margin-bottom: 36px;

    .${CAROUSEL_ITEM_CLASS} {
      margin-right: 24px;

      & img {
        max-height: 360px;
      }
    }
  }

  .${IMAGES_CLASS} {
    display: flex;
    flex-wrap: wrap;

    ${VIEWPORTS.mobile} {
      justify-content: center;
    }
  }

  .${IMAGES_ITEM_CLASS} {
    margin-bottom: 36px;
    margin-right: 40px;

    img {
      max-height: 460px;
    }

    &:last-child {
      margin-right: 0;
    }

    ${VIEWPORTS.mobile} {
      margin-right: 16px;

      &:last-child {
        margin-right: 16px;
      }
    }
  }

  .${GRID_CLASS} {
    display: flex;
    margin-bottom: 32px;

    > *:last-child {
      margin-bottom: 0;
    }

    ${VIEWPORTS.tablet} {
      margin-bottom: 24px;
    }

    ${VIEWPORTS.mobile} {
      flex-wrap: wrap;
    }
  }

  .${GRID_ITEM_SECOND_CLASS} {
    flex: 1;
    min-height: 50px;
    margin-right: 16px;

    &:not(:last-child) {
      flex: initial;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    ${VIEWPORTS.mobile} {
      margin-right: 16px;

      &:last-child {
        width: 100%;
      }
    }
  }

  .${AUTHOR_CLASS} {
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .${AUTHOR_NAME_CLASS} {
    min-height: 20px;
    flex: 1;
    line-height: 1.5;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .${BANNER_CLASS} {
    position: relative;
    margin-bottom: 36px;
    padding: 22px 14px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    cursor: pointer;
    border: 2px dashed #b3afaf;
    border-radius: 24px;
    background-clip: padding-box;
    background-color: transparent;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: black;

      .${BANNER_SECONDARY_TEXT_CLASS} {
        @media screen and (min-width: 1024px) {
          color: ${COLORS.gold};
        }
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
      padding: 22px 30px;
    }

    @media screen and (min-width: 1440px) {
      padding: 22px 26px;
    }

    .${BANNER_IMAGE_CLASS} {
      position: relative;
      max-width: 80px;
      width: 100%;

      @media screen and (min-width: 768px) {
        max-width: 160px;
      }

      &:hover {
        .${BANNER_IMAGE_REPLACE_ICON_CLASS} {
          background: rgba(0, 0, 0, 0.6);
          opacity: 1;
        }
      }
    }

    .${BANNER_IMAGE_REPLACE_ICON_CLASS} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      color: white;
      cursor: pointer;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    .${BANNER_CONTENT_CLASS} {
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: 768px) {
        padding-left: 30px;
      }

      @media screen and (min-width: 1440px) {
        padding-left: 54px;
      }
    }

    .${BANNER_TITLE_CLASS} {
      position: relative;
      margin-bottom: 12px;
      padding-bottom: 14px;
      font-family: "Figgins Sans V3 Bold";
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;

      * {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }

      @media screen and (min-width: 768px) {
        margin-bottom: 16px;
        padding-bottom: 19px;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 2px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 28px;
        line-height: 35px;
      }

      &:after {
        position: absolute;
        z-index: 1;
        content: "";
        bottom: 0;
        left: 0;
        width: 40px;
        height: 2px;
        background-color: ${COLORS.gold};

        @media screen and (min-width: 768px) {
          width: 80px;
          height: 3px;
        }
      }
    }

    .${BANNER_TEXT_CLASS} {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;

      @media screen and (min-width: 768px) {
        margin-bottom: 28px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 1px;
      }
    }

    .${BANNER_SECONDARY_TEXT_CLASS} {
      font-family: "Figgins Sans V3 Bold";
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      transition: color 0.2s ease-in;

      * {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
  }
`

export function RichContent(props: HTMLAttributes<HTMLDivElement>) {
  const { className, style, children } = props
  return createElement(Fragment, {}, [
    createElement(VendorStyles, { key: "VendorStyles" }),
    createElement(
      Root,
      {
        className,
        style,
        key: "Content",
      },
      children,
    ),
  ])
}

const VendorStyles = createGlobalStyle`
  :root {
    --ck-image-style-spacing: 1.5em;
  }
  .ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }
  .ck-content .image.image_resized img {
    width: 100%;
  }
  .ck-content .image.image_resized > figcaption {
    display: block;
  }
  .ck-content .image > figcaption {
    word-break: break-word;
    text-align: left;
    margin-top: 8px;
  }
  .ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin-bottom: 36px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .ck-content .text-tiny {
    font-size: .7em;
  }
  .ck-content .text-small {
    font-size: .85em;
  }
  .ck-content .text-big {
    font-size: 1.4em;
  }
  .ck-content .text-huge {
    font-size: 1.8em;
  }
  .ck-content blockquote {
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    font-family: "William Regular A";
  }
  .ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
  }
  .ck-content .table {
    margin: 1em auto;
    display: table;
  }
  .ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
  }
  .ck-content .table table td,
  .ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
  }
  .ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
  }
  .ck-content[dir="rtl"] .table th {
    text-align: right;
  }
  .ck-content[dir="ltr"] .table th {
    text-align: left;
  }
  .ck-content .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
  }
  .ck-content .raw-html-embed {
    margin: 0 auto 36px auto;
    min-width: 15em;
    font-style: normal;
  }
  .ck-content .raw-html-embed iframe {
    max-width: 100%;
  }
  .ck-content hr {
    background: hsl(0, 0%, 87%);
    border: 0;
    height: 5px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 24px;
    width: 92px;
  }
  .gold .ck-content hr { 
    background: ${COLORS.gold};
  }
  .red .ck-content hr { 
    background:  ${COLORS.red};
  }
  .purple .ck-content hr { 
    background:  ${COLORS.purple};
  }
  .ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
  }
  .ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
  }
`
