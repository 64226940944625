import { useState } from "react"
import NextLink from "next/link"
import { twMerge } from "tailwind-merge"
import { Contacts } from "components"
import { useGetCities, useGetCommonTranslations } from "api"
import { getCityUrl } from "lib"
import type { City } from "api"

import CloseSvg from "public/images/icons/close.svg"
import HamburgerSvg from "public/images/icons/hamburger.svg"

interface MenuProps {
  currentCity?: City
}

export function Menu(props: MenuProps) {
  const { currentCity } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { data: cities = [] } = useGetCities()
  const { data: translations } = useGetCommonTranslations()

  const openMenu = () => {
    setIsOpen(true)
    document.body.style.overflow = "hidden"
  }

  const closeMenu = () => {
    setIsOpen(false)
    document.body.style.overflow = "auto"
  }

  const menuCities = cities.filter((city) => city.articles.length)

  const linkClasses = "text-3xl lg:text-2xl transition-colors hover:text-pink"

  return (
    <div>
      <button className="block" onClick={openMenu}>
        <HamburgerSvg className="h-8 w-8" />
      </button>
      {isOpen && (
        <div
          className="fixed top-0 left-0 z-10 h-full w-full bg-black bg-opacity-60 lg:flex lg:justify-end"
          onClick={closeMenu}
        >
          <div
            className="h-full overflow-auto bg-white px-4 pt-3 pb-11 text-black md:h-auto md:py-8 md:px-10 lg:py-6 lg:pr-20"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mb-6 flex justify-end lg:mb-3">
              <button onClick={closeMenu}>
                <CloseSvg className="h-8 w-8" />
              </button>
            </div>
            <div className="flex flex-col space-y-6 lg:w-[308px]">
              {menuCities.map((city) => (
                <NextLink key={city.id} href={getCityUrl(city.slug)} prefetch>
                  <a
                    className={twMerge(
                      linkClasses,
                      "uppercase tracking-[2px]",
                      currentCity?.id === city.id &&
                        "pointer-events-none text-pink",
                    )}
                    onClick={closeMenu}
                  >
                    {city.name}
                  </a>
                </NextLink>
              ))}
            </div>

            <div className="my-8 md:my-14 lg:my-10">
              <a className={linkClasses} href={translations?.urls?.contactUs}>
                {translations?.locales["footer.contactUs"]}
              </a>
            </div>

            <Contacts linkClasses={linkClasses} />
          </div>
        </div>
      )}
    </div>
  )
}
