import { useEffect, useState } from "react"
import { getCookie, setCookies } from "cookies-next"
import { parse } from "query-string"
import { SmartBanner } from "components"
import { getSmartBanner, useGetSmartBanner } from "api"
import { SMART_BANNER_COOKIE } from "middleware"
import type { SmartBannerData } from "api"

export const setSmartBannerCookie = (
  days: number = 30,
) => {
  const addDays = () => {
    let date = new Date()
    date.setDate(date.getDate() + days)
    return date
  }

  setCookies(SMART_BANNER_COOKIE, 1, {
    expires: addDays(),
  })
}

export function useSmartBanner(smartBannerData?: SmartBannerData) {
  const [isSmartBannerOpened, setSmartBannerOpened] = useState(true)

  const showSmartBanner =
    smartBannerData &&
    smartBannerData.description &&
    smartBannerData.button_text &&
    smartBannerData.playmarket_button_text &&
    smartBannerData.appgallery_button_text

  return isSmartBannerOpened && showSmartBanner ? (
    <div className="fixed top-0 z-10 w-full">
      <SmartBanner onClose={() => setSmartBannerOpened(false)} />
    </div>
  ) : null
}
