interface SpotifyWidgetProps {
  url: string
}

export function SpotifyWidget(props: SpotifyWidgetProps) {
  const { url } = props
  return (
    <div className="h-full bg-white mix-blend-difference">
      <iframe
        src={`${url}?theme=0`}
        width="100%"
        height="100%"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      />
    </div>
  )
}
