import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { Http } from "lib"
import type { QueryClient } from "react-query"
import type { HttpParams } from "lib"
import type { City } from "./cities.types"

async function fetchCities(params: HttpParams): Promise<City[]> {
  const data = (await Http.get(`/cities`, params)) as City[]
  return data || []
}

export async function getCities(locale?: string): Promise<City[]> {
  return await fetchCities({ locale, _sort: "priority:ASC" })
}

export async function getCity(
  slug?: string,
  locale?: string,
): Promise<City | null> {
  const data = await fetchCities({ locale, slug })
  return data && data[0]
}

export const useGetCities = () => {
  const { locale } = useRouter()
  return useQuery(["cities", locale], () => getCities(locale))
}

export function prefetchCities(queryClient: QueryClient, locale?: string) {
  return queryClient.prefetchQuery(["cities", locale], () => getCities(locale))
}

export const useGetCity = (slug?: string) => {
  const { locale } = useRouter()
  return useQuery(["city", slug, locale], () => getCity(slug, locale))
}
