import { useContext } from "react"
import { useRouter } from "next/router"
import HTMLParser from "react-html-parser"
import { twMerge } from "tailwind-merge"
import { ButtonLink, Slide, Slider } from "ui"
import { ButtonDecorated, ContentTemplate, Sticker, STORES } from "components"
import { Device, LocaleContext } from "lib"
import { useGetCommonTranslations } from "api"
import { ADS } from "./pure-ads.consts"
import type { MouseEventHandler } from "react"
import type { PureAdsProps } from "./pure-ads.types"
import styles from "./pure-ads.module.css"

import CloudBottom from "public/images/cloud-3.svg"
import Diamond from "public/images/icons/diamond.svg"
import Heart from "public/images/icons/like-stroke.svg"
import PureSvg from "public/images/avatar-pure.svg"

export function PureAds(props: PureAdsProps) {
  const { theme = "white" } = props
  const data = useContext(LocaleContext)
  const { locale } = useRouter()
  const { data: translations } = useGetCommonTranslations()

  const themeClasses =
    theme === "white" ? "bg-white text-black" : "bg-black text-white"

  const isAndroid = Device.isAndroid()

  const onAdsClick = () => {
    if (!isAndroid && data.app_urls.dating) {
      window.location.href = data.app_urls.dating
    }
  }

  const ads = ADS.slice()
  if (locale !== "ru") {
    ads.splice(6, 0, {
      text: "ad.promocode.text",
      icon: <PureSvg />,
      className: "bg-purple w-[200px] md:w-[280px] lg:w-[350px]",
      isPromocode: true,
    })
  }

  const onPromocodeClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const paygateLink = translations?.urls?.paygate
    const promocode = translations?.locales["ad.promocode"] || ""
    navigator.clipboard.writeText(promocode).then(() => {
      window.open(paygateLink)
    })
  }

  return (
    <div
      className={twMerge(
        themeClasses,
        "relative overflow-hidden pt-16 pb-26 md:pt-24 md:pb-38 lg:pt-30 lg:pb-40",
      )}
    >
      <ContentTemplate className="pb-8 md:pb-16 lg:flex lg:items-center">
        <div className="text-center lg:max-w-[740px] lg:text-left">
          <h4 className="mb-4 text-3xl uppercase tracking-wider md:text-6xl">
            {HTMLParser(data.locales["dating.title"])}
          </h4>

          <p className="mb-4 text-xl md:mb-8 md:text-4xl lg:mb-0">
            {HTMLParser(data.locales["dating.paragraph"])}
          </p>
        </div>
        {isAndroid ? (
          <div className="flex space-x-2">
            <ButtonLink theme={theme} href={data.app_urls.dating || ""}>
              {STORES.google.text}
            </ButtonLink>
            <ButtonLink
              theme={theme}
              href={data.app_urls.dating_app_gallery || ""}
            >
              {STORES.huawei.text}
            </ButtonLink>
          </div>
        ) : (
          <div className="lg:mt-4 lg:ml-16">
            <ButtonDecorated theme={theme} href={data.app_urls.dating || ""}>
              {HTMLParser(data.locales["dating.button"])}
            </ButtonDecorated>
          </div>
        )}
      </ContentTemplate>

      <Slider
        className={twMerge(
          styles.swiper,
          "pt-7 pb-2.5 md:pt-11 md:pb-5 lg:pt-18 lg:pb-8",
        )}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        loop
        centeredSlides
      >
        {ads.map((ad) => (
          <Slide
            className={twMerge("w-auto", !isAndroid && "cursor-pointer")}
            key={ad.text}
            onClick={onAdsClick}
          >
            <Sticker
              className={ad.className}
              text={translations?.locales[ad.text]}
              icon={ad.icon}
            >
              {ad.isPromocode ? (
                <div className="mt-3 mb-2">
                  <span
                    className="border-b border-dashed border-black font-sans-bold text-sm uppercase tracking-wider text-black md:text-xl"
                    onClick={onPromocodeClick}
                  >
                    {translations?.locales["ad.promocode"]}
                  </span>
                </div>
              ) : (
                <div className="flex flex-row flex-nowrap items-center justify-between">
                  <span className="text-sm text-black text-opacity-30 md:text-xl">
                    {`${ad.distance} ${translations?.locales["ad.distance"]}`}
                  </span>
                  <span className="flex h-5 md:h-8">
                    <Diamond className="mr-3" />
                    <Heart />
                  </span>
                </div>
              )}
            </Sticker>
          </Slide>
        ))}
      </Slider>

      {theme === "white" && (
        <ContentTemplate className="absolute -left-40 -bottom-px md:-left-72 lg:left-0 lg:right-0">
          <CloudBottom className="h-10 text-gray-400 md:h-20 lg:absolute lg:-right-16 lg:bottom-0 xl:-right-0" />
        </ContentTemplate>
      )}
    </div>
  )
}
