import AppStoreSvg from "public/images/icons/apple-stroke.svg"
import GooglePlaySvg from "public/images/icons/google-stroke.svg"
import AppGallerySvg from "public/images/icons/appgallery-stroke.svg"
import AppStoreSolidSvg from "public/images/icons/apple-solid.svg"
import GooglePlaySolidSvg from "public/images/icons/google-solid.svg"
import AppGallerySolidSvg from "public/images/icons/appgallery-solid.svg"

import ButtonAppStoreSvg from "public/images/icons/button_appStore_full.svg"
import ButtonGooglePlaySvg from "public/images/icons/button_googlePlay_full.svg"
import ButtonAppGallerySvg from "public/images/icons/button_appGallery_full.svg"
import ButtonAppStoreShortSvg from "public/images/icons/button_appStore_logo.svg"
import ButtonGooglePlayShortSvg from "public/images/icons/button_googlePlay_logo.svg"
import ButtonAppGalleryShortSvg from "public/images/icons/button_appGallery_logo.svg"
import IconAppStoreSvg from "public/images/icons/icon_apple.svg"
import IconGooglePlaySvg from "public/images/icons/icon_googlePlay.svg"
import IconAppGallerySvg from "public/images/icons/icon_huawei.svg"

import type { StoreConfig } from "./store-link.types"
import { StoreButtonConfig } from "./store-link.types"

export const STORES: StoreConfig = {
  apple: {
    Svg: AppStoreSvg,
    SolidSvg: AppStoreSolidSvg,
    text: "App Store",
  },
  google: {
    Svg: GooglePlaySvg,
    SolidSvg: GooglePlaySolidSvg,
    text: "Google Play",
  },
  huawei: {
    Svg: AppGallerySvg,
    SolidSvg: AppGallerySolidSvg,
    text: "AppGallery",
  },
}

export const STORES_BUTTONS: StoreButtonConfig = {
  apple: {
    MobileSvg: IconAppStoreSvg,
    TabletSvg: ButtonAppStoreShortSvg,
    DesktopSvg: ButtonAppStoreSvg,
  },
  google: {
    MobileSvg: IconGooglePlaySvg,
    TabletSvg: ButtonGooglePlayShortSvg,
    DesktopSvg: ButtonGooglePlaySvg,
  },
  huawei: {
    MobileSvg: IconAppGallerySvg,
    TabletSvg: ButtonAppGalleryShortSvg,
    DesktopSvg: ButtonAppGallerySvg,
  },
}
