export const BACKGROUND_URLS = [
  "/images/card-pattern-1.svg",
  "/images/card-pattern-2.svg",
  "/images/card-pattern-3.svg",
]

export const SLIDER_GAP = 24
export const CARD_BREAKPOINT = 768
export const DESKTOP_CARD_WIDTH = 410
export const MOBILE_CARD_WIDTH = 310
