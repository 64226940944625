import { twMerge } from "tailwind-merge"
import { ButtonText } from "ui"
import { SmartBannerButtonBorders } from "../atoms"
import type { ButtonTextProps } from "ui"

export function SmartBannerButton(props: ButtonTextProps) {
  const { children, className, ...rest } = props
  const classes = `h-[39px] min-w-[88px] px-3 relative tracking-[0.5px] text-center`

  return (
    <ButtonText as="a" className={twMerge(classes, className)} {...rest}>
      <SmartBannerButtonBorders className="absolute top-0 left-0 h-full w-full" />
      <span className="relative mt-0.5 inline-block text-sm md:text-base">
        {children}
      </span>
    </ButtonText>
  )
}
