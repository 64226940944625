import { twMerge } from "tailwind-merge"
import { ButtonLink } from "ui"
import { Button } from "components"
import type { PropsWithChildren } from "react"
import type { Theme } from "lib"

import ZigzagSvg from "public/images/zigzag.svg"

type ButtonDecoratedProps = PropsWithChildren<{
  theme: Theme
  href?: string
  onClick?: () => void
}>

export function ButtonDecorated(props: ButtonDecoratedProps) {
  const { theme, href, onClick, children } = props
  const zigzagClasses = "w-[34px] h-[92px] shrink-0"

  return (
    <div className="pointer-events-none flex items-center justify-center space-x-2 transition-colors hover:text-darkgold-300 md:space-x-4">
      <ZigzagSvg className={twMerge(zigzagClasses, "-scale-x-[1]")} />
      <div className="pointer-events-auto w-full md:w-[302px]">
        {href ? (
          <ButtonLink theme={theme} href={href}>
            {children}
          </ButtonLink>
        ) : (
          <Button theme={theme} className="py-[18px] text-xl" onClick={onClick}>
            {children}
          </Button>
        )}
      </div>
      <ZigzagSvg className={zigzagClasses} />
    </div>
  )
}
