import { twMerge } from "tailwind-merge"
import type { PropsWithChildren, ReactElement } from "react"

type StickerProps = PropsWithChildren<{
  className?: string
  text?: string
  icon?: ReactElement
}>

export function Sticker(props: StickerProps) {
  const { text, icon, className, children } = props

  return (
    <div
      className={twMerge(
        "relative flex shrink-0 flex-col justify-between px-5 pt-8 pb-4 md:px-6 md:pt-11 md:pb-5",
        className,
      )}
    >
      <div className="absolute left-5 -top-6 h-12 w-12 md:-top-8 md:h-16 md:w-16 lg:-top-12 lg:h-20 lg:w-20">
        {icon}
      </div>
      <p className="mb-1 font-serif text-sm leading-5 text-gray-400 md:text-xl md:leading-7 lg:text-2xl">
        {text}
      </p>
      {children}
    </div>
  )
}
