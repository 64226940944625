import { useState, useEffect, forwardRef } from "react"
import { Navigation, Mousewheel, Keyboard, Controller } from "swiper"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import { twMerge } from "tailwind-merge"
import { mergeClasses } from "lib"

import type { ReactElement, ButtonHTMLAttributes } from "react"
import type { SwiperOptions } from "swiper"

export type IconProps = {
  title?: string
  titleId?: string
} & import("react").SVGAttributes<SVGSVGElement>

export const IconArrowLeft = forwardRef<SVGSVGElement, IconProps>(
  function IconArrowLeft(props, ref) {
    const { className, title, titleId, ...otherProps } = props
    const defaultClasses =
      "w-8 h-8 flex-shrink-0 transition-colors duration-150 ease-in focus:outline-none"
    const classes = mergeClasses(defaultClasses, className)

    return (
      <svg
        ref={ref}
        viewBox="0 0 32 32"
        fill="none"
        focusable="false"
        aria-hidden="true"
        tabIndex={-1}
        className={classes}
        aria-labelledby={titleId}
        {...otherProps}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M20.8491 3.60009L10.2491 15.6001C10.1527 15.7111 10.0996 15.8531 10.0996 16.0001C10.0996 16.1471 10.1527 16.2891 10.2491 16.4001L20.8491 28.4001C20.9014 28.4594 20.965 28.5078 21.0362 28.5425C21.1073 28.5771 21.1847 28.5973 21.2637 28.602C21.3427 28.6066 21.4218 28.5956 21.4965 28.5695C21.5713 28.5435 21.6401 28.5029 21.6991 28.4501C21.8166 28.3429 21.8876 28.194 21.8969 28.0353C21.9062 27.8765 21.8532 27.7203 21.7491 27.6001L11.4991 16.0001L21.7491 4.40009C21.8532 4.27985 21.9062 4.1237 21.8969 3.96492C21.8876 3.80614 21.8166 3.65728 21.6991 3.55009C21.6401 3.4973 21.5713 3.4567 21.4965 3.43063C21.4218 3.40456 21.3427 3.39353 21.2637 3.39818C21.1847 3.40283 21.1073 3.42306 21.0362 3.45771C20.965 3.49237 20.9014 3.54075 20.8491 3.60009Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)

export const IconArrowRight = forwardRef<SVGSVGElement, IconProps>(
  function IconArrowRight(props, ref) {
    const { className, title, titleId, ...otherProps } = props
    const defaultClasses =
      "w-8 h-8 flex-shrink-0 transition-colors duration-150 ease-in focus:outline-none"
    const classes = mergeClasses(defaultClasses, className)

    return (
      <svg
        ref={ref}
        viewBox="0 0 32 32"
        fill="none"
        focusable="false"
        aria-hidden="true"
        tabIndex={-1}
        className={classes}
        aria-labelledby={titleId}
        {...otherProps}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M11.1505 28.3999L21.7505 16.3999C21.8468 16.2889 21.8999 16.1469 21.8999 15.9999C21.8999 15.8529 21.8468 15.7109 21.7505 15.5999L11.1505 3.59991C11.0981 3.54058 11.0345 3.49219 10.9633 3.45754C10.8922 3.42289 10.8149 3.40266 10.7359 3.39801C10.6569 3.39336 10.5777 3.40439 10.503 3.43046C10.4283 3.45653 10.3594 3.49713 10.3005 3.54991C10.1829 3.65711 10.112 3.80596 10.1026 3.96475C10.0933 4.12353 10.1463 4.27968 10.2505 4.39991L20.5005 15.9999L10.2505 27.5999C10.1463 27.7201 10.0933 27.8763 10.1026 28.0351C10.112 28.1939 10.1829 28.3427 10.3005 28.4499C10.3594 28.5027 10.4283 28.5433 10.503 28.5694C10.5777 28.5954 10.6569 28.6065 10.7359 28.6018C10.8149 28.5972 10.8922 28.5769 10.9633 28.5423C11.0345 28.5076 11.0981 28.4592 11.1505 28.3999Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)

type SliderProps = {
  children: ReactElement<typeof SwiperSlide>[]
  className?: string
  options?: SwiperOptions
  classes?: {
    container?: string
    navigation?: {
      container?: string
      prev?: string
      next?: string
    }
  }
}

export function Slider(props: SliderProps) {
  const { children, className, classes, options } = props

  const [swiper, setSwiper] = useState<ReturnType<typeof useSwiper> | null>(
    null,
  )

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (swiper !== null) {
      timeout = setTimeout(() => {
        swiper.update()
      }, 1000)
    }

    return function cleanup() {
      if (swiper !== null) {
        clearTimeout(timeout)
        swiper.destroy()
      }
    }
  }, [swiper])

  function goNext() {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  function goPrev() {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  return (
    <div className={twMerge("relative", classes?.container)}>
      <Swiper
        speed={180}
        observer={true}
        observeParents={true}
        observeSlideChildren={true}
        updateOnWindowResize={true}
        modules={[Navigation, Mousewheel, Keyboard, Controller]}
        onSwiper={(swiper) => setSwiper(swiper)}
        className={className}
        {...options}
      >
        {children}
      </Swiper>
      {swiper?.params.navigation ? (
        <div
          className={twMerge(
            "absolute -right-10 -left-10 top-1/2 flex -translate-y-1/2 transform items-center justify-between",
            "lg:-right-14 lg:-left-14",
            classes?.navigation?.container,
          )}
        >
          <NavigationHandler
            className={classes?.navigation?.prev}
            icon={<IconArrowLeft className="h-10 w-10" />}
            onClick={goPrev}
          />
          <NavigationHandler
            className={classes?.navigation?.next}
            icon={<IconArrowRight className="h-10 w-10" />}
            onClick={goNext}
          />
        </div>
      ) : null}
    </div>
  )
}

type NavigationHandlerProps = {
  icon: ReactElement<typeof IconArrowRight | typeof IconArrowLeft>
} & ButtonHTMLAttributes<HTMLButtonElement>

const NavigationHandler = forwardRef<HTMLButtonElement, NavigationHandlerProps>(
  function NavigationHandler(props, ref) {
    const { className, icon, ...otherProps } = props

    const defaultClasses =
      "w-10 h-10 flex items-center justify-center text-inherit cursor-pointer opacity-50 border-none bg-transparent transition-opacity duration-100 ease-in hover:opacity-100"
    const classes = mergeClasses(defaultClasses, className)

    return (
      <button
        ref={ref}
        className={classes}
        type="button"
        aria-label="Next slide"
        {...otherProps}
      >
        {icon}
      </button>
    )
  },
)

export { SwiperSlide as SliderSlide }
