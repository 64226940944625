export const COLORS = {
  black: "#000",
  gold: "#D0BA92",
  red: "#ffb5c0",
  purple: "#d4caff",
}

export const BREAK_POINTS = {
  laptop: 1280,
  tablet: 768,
}

export const VIEWPORTS = {
  touch: `@media only screen and (max-width: ${BREAK_POINTS.laptop - 1}px)`,
  tablet: `@media only screen and (min-width: ${
    BREAK_POINTS.tablet
  }px) and (max-width: ${BREAK_POINTS.laptop - 1}px)`,
  mobile: `@media only screen and (max-width: ${BREAK_POINTS.tablet - 1}px)`,
}

export const AUTHOR_CLASS = "rich-author"
export const AUTHOR_IMAGE_CLASS = "rich-author-img"
export const AUTHOR_NAME_CLASS = "rich-author-name"
export const BANNER_CLASS = "editor-banner"
export const BANNER_CONTENT_CLASS = "editor-banner-content"
export const BANNER_IMAGE_CLASS = "editor-banner-img"
export const BANNER_IMAGE_REPLACE_ICON_CLASS = "editor-banner-replace-icon"
export const BANNER_SECONDARY_TEXT_CLASS = "editor-banner-link"
export const BANNER_TEXT_CLASS = "editor-banner-text"
export const BANNER_TITLE_CLASS = "editor-banner-heading"
export const CAROUSEL_CLASS = "rich-carousel"
export const CAROUSEL_ITEM_CLASS = "rich-carousel-item"
export const GRID_CLASS = "rich-grid"
export const GRID_ITEM_SECOND_CLASS = "rich-grid-item-second"
export const IMAGES_CLASS = "rich-images"
export const IMAGES_ITEM_CLASS = "rich-images-item"
